var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "px-0",
            class:
              _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
          },
          [
            _c(
              "b-row",
              { staticClass: "form" },
              [
                _c("b-col", { attrs: { cols: "12" } }, [
                  _c(
                    "h1",
                    {
                      class: [
                        `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                        { "is-pwa": _vm.$isPwa() },
                      ],
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(this.caption1) +
                          "\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
                _c(
                  "b-col",
                  {
                    class: `${_vm.$screen.width <= 576 ? "my-project" : ""}`,
                    attrs: { cols: "12" },
                  },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              title: _vm.FormMSG(67, "My Accomodations"),
                            },
                            on: { click: _vm.reloadData },
                          },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "slide" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass:
                                      "card-border-blue-light px-2 pt-3 pb-2",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _vm.$screen.width >= 992
                                      ? _c(
                                          "b-row",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "my-1",
                                                attrs: {
                                                  cols: `${
                                                    _vm.$screen.width >= 992
                                                      ? 6
                                                      : 0
                                                  }`,
                                                },
                                              },
                                              [
                                                _vm.$screen.width >= 992
                                                  ? _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              _vm.FormMSG(
                                                                26,
                                                                "Type to Search"
                                                              ),
                                                          },
                                                          model: {
                                                            value: _vm.filter,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.filter = $$v
                                                            },
                                                            expression:
                                                              "filter",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          {
                                                            staticClass:
                                                              "cursor-pointer",
                                                          },
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              {
                                                                staticClass:
                                                                  "btn-search",
                                                              },
                                                              [
                                                                _vm.filter
                                                                  .length === 0
                                                                  ? _c(
                                                                      _vm.getLucideIcon(
                                                                        "Search"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        staticClass:
                                                                          "icon",
                                                                        attrs: {
                                                                          color:
                                                                            "#FFFFFF",
                                                                          size: 16,
                                                                          "stroke-width": 2.5,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      _vm.getLucideIcon(
                                                                        "X"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        staticClass:
                                                                          "icon",
                                                                        attrs: {
                                                                          color:
                                                                            "#FFFFFF",
                                                                          size: 16,
                                                                          "stroke-width": 2.5,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.filter =
                                                                                ""
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c("b-col", {
                                              staticClass:
                                                "d-flex justify-content-end",
                                              attrs: {
                                                cols: `${
                                                  _vm.$screen.width >= 992
                                                    ? 6
                                                    : 12
                                                }`,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-row",
                                      { staticClass: "mt-1" },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _vm.$screen.width < 992
                                              ? _c(
                                                  "list-accomodations-mobile",
                                                  {
                                                    attrs: {
                                                      accomodations:
                                                        _vm.accommodationDeliveries,
                                                    },
                                                    on: {
                                                      "list-accomodation:call-phone":
                                                        _vm.callPhone,
                                                      "list-accomodation:view-document":
                                                        _vm.viewDocument,
                                                      "list-accomodation:go-direction":
                                                        _vm.handleDialogDetailGoDirection,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.$screen.width >= 992
                                              ? _c("b-table", {
                                                  ref: "myTable",
                                                  staticStyle: {
                                                    "text-align": "left",
                                                  },
                                                  attrs: {
                                                    hover: _vm.hover,
                                                    "sticky-header": "547px",
                                                    responsive: "sm",
                                                    selectable: "",
                                                    selectedVariant:
                                                      _vm.selectedColor,
                                                    "select-mode":
                                                      _vm.selectMode,
                                                    items:
                                                      _vm.accommodationDeliveries,
                                                    fields: _vm.accEntryFields,
                                                    filter: _vm.filter,
                                                    bordered: "",
                                                    striped: "",
                                                    small: "",
                                                    "head-variant": _vm.hv,
                                                    "empty-text": _vm.FormMSG(
                                                      51,
                                                      "No accommodation found"
                                                    ),
                                                    "show-empty": "",
                                                    busy: _vm.isTableBusy,
                                                    outlined: "",
                                                  },
                                                  on: {
                                                    "row-dblclicked":
                                                      _vm.handleViewData,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "cell(attachment)",
                                                        fn: function (data) {
                                                          return [
                                                            data.item.fileName
                                                              .length > 0
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "px-1",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handlePreviewDocument(
                                                                            data.item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: `svgs/bootstrap/filetype-${_vm.getExtensionFile(
                                                                          data
                                                                            .item
                                                                            .fileName
                                                                        )}.svg`,
                                                                        width:
                                                                          "26",
                                                                        height:
                                                                          "26",
                                                                        id: _vm.generateTextId(
                                                                          `view-${data.item.id}`
                                                                        ),
                                                                      },
                                                                    }),
                                                                    _c(
                                                                      "b-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          target:
                                                                            _vm.generateTextId(
                                                                              `view-${data.item.id}`
                                                                            ),
                                                                          placement:
                                                                            "left",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              data
                                                                                .item
                                                                                .fileName
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(status)",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "wrap-status",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    class: `status ${data.item.statusClass}`,
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "0.7rem",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .statusText
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(view)",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "Eye"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#225CBD",
                                                                  size: 20,
                                                                  "stroke-width": 2,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleViewData(
                                                                        data.item
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(map)",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              "b-button",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "b-tooltip",
                                                                    rawName:
                                                                      "v-b-tooltip.hover.bottom.html",
                                                                    value: `${_vm.FormMSG(
                                                                      70,
                                                                      "See place of accommodation"
                                                                    )}`,
                                                                    expression:
                                                                      "`${FormMSG(70, 'See place of accommodation')}`",
                                                                    modifiers: {
                                                                      hover: true,
                                                                      bottom: true,
                                                                      html: true,
                                                                    },
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "btn bg-transparent border-0",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleDialogDetailGoDirection(
                                                                        data.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getLucideIcon(
                                                                    "Map"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      color:
                                                                        "#225CBD",
                                                                      size: 20,
                                                                      "stroke-width": 2,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "table-busy",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-center text-success my-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-spinner",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle",
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1421749764
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("view-modal", {
                                      attrs: { item: _vm.item, action: "VIEW" },
                                      on: {
                                        "accomodation-update-form-modal:closed":
                                          _vm.onViewModalCancelled,
                                        "accomodation-update-form-modal:reload":
                                          _vm.onViewModalReloaded,
                                      },
                                      model: {
                                        value: _vm.isAccomodationViewOpen,
                                        callback: function ($$v) {
                                          _vm.isAccomodationViewOpen = $$v
                                        },
                                        expression: "isAccomodationViewOpen",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.$screen.width >= 576 && _vm.canSeeAccomodation
                          ? _c(
                              "b-tab",
                              {
                                attrs: {
                                  lazy: "",
                                  title: _vm.FormMSG(68, "Accomodation List"),
                                },
                              },
                              [_c("accommodation-list")],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }