<style lang="scss" scoped></style>
<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'" class="px-0">
				<b-row class="form">
					<b-col cols="12">
						<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
							{{ this.caption1 }}
						</h1>
					</b-col>
					<b-col cols="12" :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
						<b-tabs cols="12">
							<b-tab :title="FormMSG(67, 'My Accomodations')" @click="reloadData">
								<transition name="slide">
									<b-card no-body class="card-border-blue-light px-2 pt-3 pb-2">
										<b-row v-if="$screen.width >= 992" class="d-flex align-items-center">
											<b-col :cols="`${$screen.width >= 992 ? 6 : 0}`" class="my-1">
												<b-input-group v-if="$screen.width >= 992">
													<b-form-input v-model="filter" type="text" :placeholder="FormMSG(26, 'Type to Search')" />
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getLucideIcon('Search')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																v-if="filter.length === 0"
															/>
															<component
																:is="getLucideIcon('X')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																@click="filter = ''"
																v-else
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
											<b-col :cols="`${$screen.width >= 992 ? 6 : 12}`" class="d-flex justify-content-end"></b-col>
										</b-row>
										<b-row class="mt-1">
											<b-col cols="12">
												<!-- <card-list-builder
													v-if="$screen.width < 992"
													:use-new-version="true"
													:has-badge="true"
													:is-badge-use-props="true"
													:hide-status="false"
													:toggle-mode="true"
													:items="accommodationDeliveries"
													:fields="omit(accEntryFields, ['status', 'view'])"
													badge-class="statusClass"
													badge-value="statusText"
												>
													<template slot="attachment" slot-scope="data">
														<div class="pr-0" @click="handlePreviewDocument(data.item)" v-if="data.item.fileName.length > 0">
															<img
																:src="`svgs/bootstrap/filetype-${getExtensionFile(data.item.fileName)}.svg`"
																width="24"
																height="24"
																:id="generateTextId(`view-${data.item.id}`)"
															/>
															<b-tooltip :target="generateTextId(`view-${data.item.id}`)" placement="left">
																{{ data.item.fileName }}
															</b-tooltip>
														</div>
													</template>
													<template slot="actions" slot-scope="data">
														<b-button
															variant="primary"
															size="sm"
															@click="handleViewData(data.item)"
															class="btn bg-transparent border-0"
														>
															<component :is="getLucideIcon('Eye')" color="#225CBD" :size="20" :stroke-width="2" />
														</b-button>
													</template>
												</card-list-builder> -->
												<list-accomodations-mobile
													v-if="$screen.width < 992"
													:accomodations="accommodationDeliveries"
													@list-accomodation:call-phone="callPhone"
													@list-accomodation:view-document="viewDocument"
													@list-accomodation:go-direction="handleDialogDetailGoDirection"
												/>
												<b-table
													v-if="$screen.width >= 992"
													:hover="hover"
													sticky-header="547px"
													responsive="sm"
													ref="myTable"
													selectable
													:selectedVariant="selectedColor"
													:select-mode="selectMode"
													:items="accommodationDeliveries"
													style="text-align: left"
													:fields="accEntryFields"
													:filter="filter"
													bordered
													striped
													small
													:head-variant="hv"
													:empty-text="FormMSG(51, 'No accommodation found')"
													show-empty
													:busy="isTableBusy"
													outlined
													@row-dblclicked="handleViewData"
												>
													<template #cell(attachment)="data">
														<div @click="handlePreviewDocument(data.item)" class="px-1" v-if="data.item.fileName.length > 0">
															<img
																:src="`svgs/bootstrap/filetype-${getExtensionFile(data.item.fileName)}.svg`"
																width="26"
																height="26"
																:id="generateTextId(`view-${data.item.id}`)"
															/>
															<b-tooltip :target="generateTextId(`view-${data.item.id}`)" placement="left">
																{{ data.item.fileName }}
															</b-tooltip>
														</div>
													</template>
													<template #cell(status)="data">
														<div class="wrap-status">
															<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
																{{ data.item.statusText }}
															</div>
														</div>
													</template>
													<template #cell(view)="data">
														<component
															:is="getLucideIcon('Eye')"
															color="#225CBD"
															:size="20"
															:stroke-width="2"
															@click="handleViewData(data.item)"
														/>
													</template>
													<template #cell(map)="data">
														<b-button
															class="btn bg-transparent border-0"
															v-b-tooltip.hover.bottom.html="`${FormMSG(70, 'See place of accommodation')}`"
															@click="handleDialogDetailGoDirection(data.item)"
														>
															<component :is="getLucideIcon('Map')" color="#225CBD" :size="20" :stroke-width="2" />
														</b-button>
													</template>
													<template #table-busy>
														<div class="text-center text-success my-2">
															<b-spinner class="align-middle"></b-spinner>
														</div>
													</template>
												</b-table>
											</b-col>
										</b-row>
										<view-modal
											v-model="isAccomodationViewOpen"
											:item="item"
											action="VIEW"
											@accomodation-update-form-modal:closed="onViewModalCancelled"
											@accomodation-update-form-modal:reload="onViewModalReloaded"
										/>
									</b-card>
								</transition>
							</b-tab>
							<b-tab lazy v-if="$screen.width >= 576 && canSeeAccomodation" :title="FormMSG(68, 'Accomodation List')">
								<accommodation-list />
							</b-tab>
						</b-tabs>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>
<script>
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { generateSecureId } from '@/shared/utils';
import { myAccomodationDeliveries, updateAccomodationDelivery } from '@/cruds/accomodation.crud';
import { recalculateDistanceValueByUnit } from '@/shared/google/helpers';
import { classDocumentStatus } from '@/shared/global-status';
import ViewModal from '@/components/Accomodation/UpdateModal';
import AccommodationList from './accomodationList.vue';
import ListAccomodationsMobile from '@/components/MyAccomodationsMobile/ListAccomodationsMobile';

const NOT_RECEIVED = 1;
const RECEIVED_NOT_READ = 2;
const READ = 3;

export default {
	name: 'MyAccomodations',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		canSeeAccomodation() {
			return store.canManageAccommodations();
		},
		accEntryFields() {
			return [
				{
					key: 'acc.reference',
					label: 'N°',
					class: 'text-center',
					sortable: true
				},
				{
					key: 'startDate',
					label: this.FormMSG(52, 'Date start'),
					class: 'text-center',
					sortable: true,
					formatter: (value, key, item) => {
						if (_.has(item, 'startTime')) {
							return moment(value).utc().format('DD/MM/YYYY') + ' ' + item.startTime;
						}

						return moment(value).utc().format('DD/MM/YYYY HH:mm');
					}
				},
				{
					key: 'endDate',
					label: this.FormMSG(53, 'Date end'),
					class: 'text-center',
					sortable: true,
					formatter: (value, key, item) => {
						if (_.has(item, 'endTime')) {
							return moment(value).utc().format('DD/MM/YYYY') + ' ' + item.endTime;
						}

						return moment(value).utc().format('DD/MM/YYYY HH:mm');
					}
				},
				{
					key: 'acc.category',
					label: this.FormMSG(54, 'Category'),
					class: 'text-center',
					sortable: true,
					formatter: (value) => {
						return this.getTextOption(value, this.categoryOptions);
					}
				},
				{
					key: 'acc.type',
					label: this.FormMSG(55, 'Type'),
					class: 'text-center',
					sortable: true,
					formatter: (value, key, item) => {
						const travels = this.travelTypeOptions;
						const accommodations = this.accomodationTypeOptions;

						if (+item.acc.category === 0) {
							return this.getTextOption(value, accommodations);
						} else if (+item.acc.category === 1) {
							return this.getTextOption(value, travels);
						}

						return '';
					}
				},
				{
					key: 'acc.description',
					label: this.FormMSG(56, 'Description'),
					sortable: false
				},
				{
					key: 'acc.supplier.name',
					label: this.FormMSG(57, 'Supplier'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'numberOfNights',
					label: this.FormMSG(59, 'Nights'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'acc.totalDistance',
					label: this.distanceUnit,
					sortable: true,
					class: 'text-center',
					formatter: (value) => {
						return recalculateDistanceValueByUnit(value);
					}
				},
				{
					key: 'status',
					label: this.FormMSG(61, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'attachment',
					label: this.FormMSG(60, 'Attachment'),
					sortable: false,
					class: 'text-center',
					isSlot: true
				},
				{
					key: 'view',
					label: this.FormMSG(62, 'View'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'map',
					label: this.FormMSG(69, 'Map'),
					class: 'text-center',
					sortable: true
				}
			];
		},
		caption1() {
			return this.FormMSG(1, 'My accomodations');
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		categoryOptions() {
			let menus = this.FormMenu(1403);

			return menus;
		},
		boatOptions() {
			let menus = this.FormMenu(1404);

			return menus;
		},
		busOptions() {
			let menus = this.FormMenu(1405);

			return menus;
		},
		carOptions() {
			let menus = this.FormMenu(1406);

			return menus;
		},
		otherOptions() {
			let menus = this.FormMenu(1407);

			return menus;
		},
		travelTypeOptions() {
			return [...this.boatOptions, ...this.busOptions, ...this.carOptions, ...this.otherOptions];
		},
		hotelOptions() {
			let menus = this.FormMenu(1415);

			return menus;
		},
		houseOptions() {
			let menus = this.FormMenu(1417);

			return menus;
		},
		appartOptions() {
			let menus = this.FormMenu(1416);

			return menus;
		},
		accomodationTypeOptions() {
			return [...this.hotelOptions, ...this.houseOptions, ...this.appartOptions];
		}
	},
	components: {
		ViewModal,
		AccommodationList,
		ListAccomodationsMobile
	},
	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			currentPage: 1,
			perPage: 0,
			warning: '',
			filter: '',
			selectMode: 'single',
			selectedColor: 'primary',
			accommodationDeliveries: [],
			isTableBusy: false,
			isAccomodationViewOpen: false,
			item: {},
			Accs: []
		};
	},
	methods: {
		async reloadData() {
			this.isTableBusy = true;
			this.accommodationDeliveries = await myAccomodationDeliveries();

			for (let i = 0; i < this.accommodationDeliveries.length; i++) {
				if (this.accommodationDeliveries[i].status === NOT_RECEIVED) {
					await updateAccomodationDelivery(+this.accommodationDeliveries[i].id, {
						status: RECEIVED_NOT_READ
					}).then((result) => {
						if (result) {
							this.accommodationDeliveries[i].status = RECEIVED_NOT_READ;
						}
					});
				}
				this.accommodationDeliveries[i].statusText = this.statusText(this.accommodationDeliveries[i].status);
				this.accommodationDeliveries[i].statusClass = classDocumentStatus(this.accommodationDeliveries[i].status);
			}
			// console.log({ accommodationDeliveries: this.accommodationDeliveries });
			this.isTableBusy = false;
		},
		callPhone(phone) {
			window.location.href = 'tel://' + phone;
		},
		viewDocument(item) {
			if (item.fileConfirmation.length > 0) {
				let urlStr = process.env.VUE_APP_GQL + '/images/' + item.fileConfirmation;
				window.open(urlStr);
			}
		},
		handleDialogDetailGoDirection(payload) {
			var filled = false;
			let supplier = payload.acc;
			if (
				((typeof supplier.address.lat === 'string' && supplier.address.lat.length > 0) || supplier.address.lat > 0) &&
				((typeof supplier.address.lng === 'string' && supplier.address.lng.length > 0) || supplier.address.lng > 0)
			) {
				var urlStr = 'http://www.google.com/maps/place/' + supplier.address.lat + ',' + supplier.address.lng;
				window.open(urlStr); // in a new window
			} else {
				let urlStr = 'https://www.google.com/maps/dir/' + payload.acc.fromAddress + '/' + payload.acc.toAddress;
				window.open(urlStr);
			}
			// else if (supplier.name.length > 0 || address.street.length > 0) {
			// 	// build address string
			// 	var urlStr = 'https://www.google.com/maps/search/?api=1&query=';
			// 	if (supplier.name.length > 0) {
			// 		urlStr += supplier.name;
			// 		filled = true;
			// 	}
			// 	if (supplier.address.street.length > 0) {
			// 		if (filled) {
			// 			urlStr += '+';
			// 		}
			// 		urlStr += supplier.address.street;
			// 	}
			// 	if (supplier.address.number.length > 0) {
			// 		if (filled) {
			// 			urlStr += '+';
			// 		}
			// 		urlStr += supplier.address.number;
			// 	}
			// 	if (supplier.address.zip.length > 0) {
			// 		if (filled) {
			// 			urlStr += '+';
			// 		}
			// 		urlStr += supplier.address.zip;
			// 	}
			// 	if (supplier.address.city.length > 0) {
			// 		if (filled) {
			// 			urlStr += '+';
			// 		}
			// 		urlStr += supplier.address.city;
			// 	}
			// 	if (supplier.address.country.length > 0) {
			// 		if (filled) {
			// 			urlStr += '+';
			// 		}
			// 		urlStr += supplier.address.country;
			// 	}
			// 	window.open(urlStr); // in a new window
			// }
		},
		statusText(value) {
			if (value === 0) {
				return this.FormMSG(63, 'Not published');
			}

			if (value === 1) {
				return this.FormMSG(64, 'Not received');
			}

			if (value === 2) {
				return this.FormMSG(65, 'Received (not read)');
			}

			if (value === 3) {
				return this.FormMSG(66, 'Read');
			}

			if (value === 4) {
				return this.FormMSG(77, 'Signed');
			}
		},
		getTextOption(value, options) {
			const text = options.filter((option) => option.value === value);

			return text.length > 0 ? text[0].text : '';
		},
		getExtensionFile(filename) {
			return filename.substring(filename.lastIndexOf('.') + 1);
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		async handlePreviewDocument(item) {
			const fileToPreview = {
				...item,
				src: `${process.env.VUE_APP_GQL}/images/${item.confirmation}`,
				xid: item.confirmation,
				ext: this.getExtensionFile(item.fileName)
			};

			this.$previewImages({
				name: 'mydocumentdetails-previewer-elem',
				images: [fileToPreview],
				focusIndex: 0,
				options: {
					presentationMode: 'doc',
					// showStatusFileTag: true,
					hideCommentButton: false,
					hideDisLikeButton: false,
					hideLikeDislike: false,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: true
				}
			});
			if (+item.status !== READ) {
				await updateAccomodationDelivery(+item.id, {
					status: READ
				}).then(async (result) => {
					if (result) {
						await this.reloadData();
					}
				});
			}
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		async handleViewData(item) {
			this.item = item;
			this.isAccomodationViewOpen = true;
			if (+this.item.status !== READ && +this.item.status !== RECEIVED_NOT_READ) {
				await updateAccomodationDelivery(+this.item.id, {
					status: RECEIVED_NOT_READ
				}).then(async (result) => {
					if (result) {
						await this.reloadData();
					}
				});
			}
		},
		onViewModalCancelled(payload) {
			this.isAccomodationViewOpen = false;
		},
		omit(objects, keys) {
			return objects.filter((object) => !keys.includes(object.key));
		},
		async onViewModalReloaded() {
			await this.reloadData();
		},
		testWhenContent() {
			console.log('Called');
		}
	},
	async created() {
		await this.reloadData();
	}
};
</script>
<style scoped>
.container-mobile .card {
	background-color: #ffffff !important;
}
</style>
